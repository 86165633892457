<div class="header">
  <div class="header-child">
    <div class="header-logo-container">
      <a routerLink="/" class="app-title">
        <img src="../../../../../assets/viessmann-white.svg" class="logo-img" alt="" />
        <span class="title">{{ 'Vitoguide-Trial' }}</span>
      </a>
    </div>
    <div class="header-menu-container">
      <vih-support-link></vih-support-link>
      <vih-app-switcher></vih-app-switcher>
      <vih-login></vih-login>
    </div>
  </div>
</div>
