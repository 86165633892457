import { Component, HostListener, OnInit } from '@angular/core';
import { CountryModel } from './models/country.model';
import { CustomerModel } from './models/customer.model';
import { TrialConfigurationService } from './services/trial.configuration.service';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AddDialogComponent } from './components/add-dialog/add-dialog.component';
import { Duration } from './models/duration.model';
import { UserFormComponent } from '../../components/user-form.component';
import deepEqual from 'deep-equal';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {forkJoin} from "rxjs";
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class HomeComponent extends UserFormComponent implements OnInit {
  public screenBreakpointReached: boolean = false;

  public countryConfigs: CountryModel[] | undefined;
  public countryConfigsSorted: CountryModel[] | undefined;
  public customerConfigs: CustomerModel[] | undefined;
  public customerConfigsTableSource: MatTableDataSource<CustomerModel> = new MatTableDataSource<CustomerModel>();

  displayedColumnsCustomer: string[] = ['id', 'amount', 'duration'];
  public editedCustomer: CustomerModel | undefined = undefined;
  public editedCustomerOriginal: CustomerModel | undefined = undefined;
  public editedDurationUnit: string | undefined;
  public editedDurationLength: number | undefined;
  public saving: boolean = false;

  constructor(public trialConfigurationService: TrialConfigurationService, private dialog: MatDialog, breakpointObserver: BreakpointObserver) {
    super();
    breakpointObserver.observe('(max-width: 800px)').subscribe((state: BreakpointState) => {
      this.screenBreakpointReached = state.matches;
    });
  }

  ngOnInit(): void {
    this.reloadCountryData();
  }

  public onTabChanged(index: number): void {
    switch (index) {
      case 1:
        if (!this.customerConfigs) {
          this.reloadCustomerData()
        }
        break;
    }
  }

  public reloadCountryData(): void {
    forkJoin([
      this.trialConfigurationService.getCountryData(),
      this.trialConfigurationService.getBasePrices()
    ]).subscribe(([countryModels, subscriptionPlans]) => {
      countryModels.map(country => {
        subscriptionPlans.map(subscriptionPlan => {
            if (country.vkorg == subscriptionPlan.vkorg) {
              country.subscriptionPlans = subscriptionPlan;
          }
        })
      })
      this.countryConfigs = countryModels;
      this.countryConfigsSorted = countryModels.slice();
      });

  }

  public changedCountry(country: CountryModel): void {
    const index: number | undefined = this.countryConfigs?.findIndex(x => x.vkorg === country.vkorg);
    const indexSorted: number | undefined = this.countryConfigsSorted?.findIndex(x => x.vkorg === country.vkorg);
    if (!this.countryConfigs || !this.countryConfigsSorted || index == undefined || indexSorted == undefined || index < 0 || indexSorted < 0) {
      this.reloadCountryData();
    } else {
      this.countryConfigs[index] = {...country, duration: {...country.duration} as Duration};
      this.countryConfigsSorted[indexSorted] = this.countryConfigs[index];
    }
  }

  public reloadCustomerData(input?: string, page: number = 1, pageSize: number = 5): void {
    this.trialConfigurationService.getCustomerData(input, page, pageSize).subscribe(customers => {
      this.customerConfigs = customers;
      if (this.editedCustomerOriginal) {
        const index: number = customers.findIndex(x => x.companyNumber === this.editedCustomerOriginal?.companyNumber);
        if (index < 0) {
          this.customerConfigs.unshift(this.editedCustomerOriginal);
        } else {
          this.customerConfigs[index] = this.editedCustomerOriginal;
        }
      }
      this.customerConfigsTableSource = new MatTableDataSource<CustomerModel>(customers);
    })
  }

  public onSortChanged(sort: Sort): void {
    if (!this.countryConfigs || !this.countryConfigsSorted || !sort.active || sort.direction === '') {
      this.countryConfigsSorted = this.countryConfigs?.slice();
      return;
    }
    const isAsc = sort.direction === 'asc';
    this.countryConfigsSorted = this.countryConfigsSorted.sort((a: CountryModel, b: CountryModel) => {
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'amount':
          return compare(a.amount, b.amount, isAsc);
        case 'duration':
          return compare(durationInDays(a.duration), durationInDays(b.duration), isAsc);
        case 'trialOnly':
          return a.trialOnly === b.trialOnly ? 0 : (a.trialOnly ? 1 : -1) * (isAsc ? 1 : -1);
    default:
          return 0;
      }
    });
  }

  public editCustomer(customer: CustomerModel): void {
    if (this.customerConfigs && !this.editedCustomerOriginal) {
      this.editedCustomerOriginal = customer;
      this.trialConfigurationService.getAdditionellCustomerData(customer).subscribe(c => {
        this.editedCustomer = c;
        if (c.duration) {
          this.editedDurationUnit = Object.keys(c.duration)[0];
          this.editedDurationLength = c.duration[this.editedDurationUnit];
        }
      },
        () => this.editedCustomerOriginal = undefined );
    }
  }

  public setDurationLength(durationLength: number): void {
    if (this.editedCustomer && this.editedDurationUnit) {
      this.editedDurationLength = durationLength;
      if (!this.editedCustomer.duration) {
        this.editedCustomer.duration = {} as Duration;
      }
      this.editedCustomer.duration[this.editedDurationUnit] = durationLength;
    }
  }

  public setDurationUnit(unit: string): void {
    if (this.editedCustomer && this.editedDurationLength) {
      this.editedDurationUnit = unit;
      this.editedCustomer.duration = {} as Duration;
      this.editedCustomer.duration[unit] = this.editedDurationLength;
    }
  }

  public cancelEditCustomer(): void {
    this.editedCustomer = undefined;
    this.editedCustomerOriginal = undefined;
  }

  public saveCustomer(): void {
    if (!this.editedCustomer) {
      this.dialog.open(ErrorDialogComponent).componentInstance.$errData = {
        status: -1,
        message: 'global-error-occurred'
      }
      return;
    }
    this.saving = true;
    this.trialConfigurationService.saveCustomerConfiguration(this.editedCustomer).pipe(
      catchError(err => {
        this.dialog.open(ErrorDialogComponent).componentInstance.$errData = {
          status: err.status,
          message: err.message
        }
        this.saving = false;
        throw(err);
      })).subscribe(() => {
      if (this.customerConfigs && this.editedCustomer && this.editedCustomerOriginal) {
        this.customerConfigs[this.customerConfigs.indexOf(this.editedCustomerOriginal)] = this.editedCustomer;
        this.customerConfigsTableSource = new MatTableDataSource<CustomerModel>(this.customerConfigs);
        this.cancelEditCustomer();
      } else {
        this.reloadCustomerData();
      }
      this.saving = false;
    });
  }

  public deleteCustomer() {
    this.trialConfigurationService.deleteCustomerConfiguration(this.editedCustomer!.companyNumber).subscribe(() => {
      if (this.customerConfigs && this.editedCustomerOriginal) {
        this.customerConfigs.splice(this.customerConfigs.indexOf(this.editedCustomerOriginal), 1);
        this.editedCustomer = undefined;
        this.editedCustomerOriginal = undefined;
        this.customerConfigsTableSource = new MatTableDataSource<CustomerModel>(this.customerConfigs);
      }
    });
  }

  public pageChanged(input: string, event: PageEvent): void {
    this.customerConfigs = undefined;
    this.customerConfigsTableSource = new MatTableDataSource<CustomerModel>();
    this.reloadCustomerData(input, event.pageIndex + 1, event.pageSize);
  }

  public addCountry(): void {
    this.dialog.open(AddDialogComponent, {disableClose: true}).componentInstance.createCountry().afterClosed().subscribe(data => {
      if (data.country && this.countryConfigs) {
        const index: number = this.countryConfigs.findIndex(countryList => countryList.vkorg === data.country.vkorg);
        if (index > -1) {
          this.countryConfigs[index] = data.country;
          if (this.countryConfigsSorted) {
            this.countryConfigsSorted[this.countryConfigsSorted.findIndex(countryList => countryList.vkorg === data.country.vkorg)] = data.country;
          }
        } else {
          this.countryConfigs.push(data.country);
          this.countryConfigsSorted?.push(data.country);
        }
      }
    });
  }

  public addCustomer(): void {
    this.dialog.open(AddDialogComponent, {disableClose: true}).componentInstance.createCustomer().afterClosed().subscribe(data => {
      if (data.customer && this.customerConfigs) {
        const index = this.customerConfigs.findIndex(customerList => customerList.companyNumber === data.customer.companyNumber);
        if (index > -1) {
          this.customerConfigs[index] = data.customer;
        } else {
          this.customerConfigs.push(data.customer);
        }
        this.customerConfigsTableSource = new MatTableDataSource<CustomerModel>(this.customerConfigs);
      }
    });
  }

  hasUnsavedData(): boolean {
    return this.editedCustomer != undefined && this.editedCustomerOriginal != undefined &&
      !(this.editedCustomer.totalAvailableTrials === this.editedCustomerOriginal.totalAvailableTrials && deepEqual(this.editedCustomer.duration, this.editedCustomerOriginal.duration));
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event: BeforeUnloadEvent): boolean {
    return super.onBeforeUnload($event);
  }
}

function compare(a: number | string | undefined, b: number | string | undefined, isAsc: boolean): number {
  return (a === b ? 0 : 1) * (!a || !b ? (!a ? -1 : 1) : (a < b ? -1 : 1)) * (isAsc ? 1 : -1);
}

function durationInDays(duration: Duration | undefined): number | undefined {
  return !duration ? undefined : duration.years * 365 || duration.months * 30 || duration.weeks * 7 || duration.days;
}
