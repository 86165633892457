<h2 mat-dialog-title>{{'home-add-dialog.component.html-heading' | translate}}</h2>
<fieldset [disabled]="saving" class="fieldset">
  <mat-dialog-content>
    <div *ngIf="country">
      <mat-form-field appearance="fill" *ngIf="country.vkorg != undefined">
        <mat-label>{{'home-add-dialog.component.html-vkorg' | translate}}</mat-label>
        <input #vkorgInput matInput placeholder="{{'global-forexample' | translate}} 8100" [(ngModel)]="country.vkorg" (keyup)="updateCountryInfo(vkorgInput.value)">
      </mat-form-field>
      <div *ngIf="country.name">
        <img *ngIf="country.code" src="https://www.countryflags.io/{{country.code}}/shiny/16.png"
             class="country-icon" alt=""/>
          {{country.name | translate}}
      </div>
    </div>
    <div *ngIf="customer">
      <mat-form-field appearance="fill">
        <mat-label>{{'home-home.component.html-tableheader-customernumber' | translate}}</mat-label>
        <input matInput [formControl]="customerFormControl" placeholder="{{'global-forexample' | translate}} 0090075423" [(ngModel)]="customer.companyNumber" (blur)="validateCustomerNumber()?.subscribe()" (keyup.enter)="validateCustomerNumber()?.subscribe()">
        <mat-error *ngIf="customerFormControl.hasError('invalidCustomerNumber')">{{'home-add-dialog.component.html-error-invalidcustomer' | translate:{value: customerFormControl.getError('invalidCustomerNumber')} }}</mat-error>
      </mat-form-field>
      <p>
        <img *ngIf="customer.countryCode"
              src="https://www.countryflags.io/{{customer.countryCode}}/shiny/16.png"
             class="country-icon" alt="">
        {{customer.name}}</p>
      <p>{{customer.email}}</p>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="saving"></mat-progress-bar>
  </mat-dialog-content>
  <mat-dialog-actions>
    <!--Cancel-->
    <button mat-raised-button (click)="cancel()">{{'global-cancel' | translate}}</button>
    <!--Save-->
    <button mat-raised-button color="primary" (click)="saveConfiguration()">{{'global-save' | translate}}</button>
  </mat-dialog-actions>
</fieldset>
