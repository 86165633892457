import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationConfigService {
  public static readonly translationsBaseUrl = 'https://api.viessmann.com/translations/v2/';
  public static readonly phraseProjectId = 'bda36669dee033ed273059f67e64ffe9';

  readonly defaultLanguage = 'en-US';
  readonly supportedLanguages = [
    'de-DE',
    'en-US',
    'pl-PL'
  ];

  constructor(private translate: TranslateService) {
  }

  setLanguage(language?: string): void {
    let supportedLanguage = this.findSupportedLanguage(language ? language : navigator.language);

    if (!supportedLanguage) {
      supportedLanguage = this.defaultLanguage;
    }

    this.translate.setDefaultLang(this.defaultLanguage);
    this.translate.use(supportedLanguage);
  }

  private findSupportedLanguage(language: string): string | undefined {
    let supportedLanguage = this.supportedLanguages.find(
      l => l.toLocaleLowerCase() === language.toLocaleLowerCase());

    if (supportedLanguage) {
      return supportedLanguage;
    }

    supportedLanguage = this.supportedLanguages.find(l =>
      l.toLocaleLowerCase().startsWith(language.split('-')[0].toLocaleLowerCase()));

    return supportedLanguage;
  }
}
