/* eslint-disable sonarjs/no-duplicate-string */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {User} from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  requiredRoles = ['Rsa.Default'];
  constructor(private http: HttpClient) {}

  public user$ = this.http
    .get<User>(`${environment.usersUrl}`, {
      params: {
        sections: ['identity', 'roles'],
      },
    })
    .pipe(shareReplay());

  public userName$ = this.user$.pipe(map((user) => `${user.name.firstName} ${user.name.familyName}`));

  public hasViGuideTrialAccess$ = this.user$.pipe(
    map((user) => {
      const identityType = user.properties.find((item: any) => item.name === 'IdentityType')?.value.toLowerCase();

      return (
        identityType?.startsWith('vi') ||
        this.requiredRoles.every((role) => user.applicationRoles.includes(role))
      );
    })
  );
}
