import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { CountryListItemComponent } from './components/country-list-item/country-list-item.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeRoutingModule } from './home-routing.module';
import { MatDividerModule } from '@angular/material/divider';
import { TrialConfigurationService } from './services/trial.configuration.service';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalHeaderModule } from '@vi/global-header';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './components/footer/footer.component';
import { LicenseDialogComponent } from './components/license-dialog/license-dialog.component';
import { AddDialogComponent } from './components/add-dialog/add-dialog.component';
import { UnsavedChangesGuardService } from '../../services/unsaved-changes.guard.service';
import { SubscriptionItemComponent } from './components/subscription-item/subscription-item.component';


@NgModule({
    declarations: [
        HomeComponent,
        ErrorDialogComponent,
        CountryListItemComponent,
        HeaderComponent,
        FooterComponent,
        LicenseDialogComponent,
        AddDialogComponent,
        SubscriptionItemComponent
    ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatTabsModule,
        MatExpansionModule,
        MatDividerModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        GlobalHeaderModule,
        MatProgressBarModule,
        MatSortModule,
        MatCheckboxModule,
        MatSelectModule,
        MatOptionModule,
        MatChipsModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        MatTableModule,
        TranslateModule
    ],
    exports: [
        FooterComponent,
        HeaderComponent
    ],
    providers: [
      TrialConfigurationService,
      UnsavedChangesGuardService
    ]
})
export class HomeModule { }
