import { CountryModel } from '../app/modules/home/models/country.model';
import { ConfigModel } from '../app/models/config.model';

export class CountryConfiguration {
  public static config: ConfigModel<CountryModel> = {
    '0500': {
      code: 'DE',
      name: 'global-germany',
      currency: 'EUR'
    },
    '0600': {
      code: 'FR',
      name: 'global-france',
      currency: 'EUR'
    },
    '2000': {
      code: 'CN',
      name: 'global-china',
      currency: 'CNY'
    },
    '5000': {
      code: 'AT',
      name: 'global-austria',
      currency: 'EUR'
    },
    '5900': {
      code: 'NL',
      name: 'global-netherlands',
      currency: 'EUR'
    },
    '6000': {
      code: 'BE',
      name: 'global-belgium',
      currency: 'EUR'
    },
    '6100': {
      code: 'LU',
      name: 'global-luxembourg',
      currency: 'EUR'
    },
    '6200': {
      code: 'CH',
      name: 'global-switzerland',
      currency: 'CHF'
    },
    '6600': {
      code: 'DK',
      name: 'global-denmark',
      currency: 'DKK'
    },
    '6800': {
      code: 'GB',
      name: 'global-greatbritain',
      currency: 'GBP'
    },
    '7200': {
      code: 'ES',
      name: 'global-spain',
      currency: 'EUR'
    },
    '7300': {
      code: 'IT',
      name: 'global-italy',
      currency: 'EUR'
    },
    '7400': {
      code: 'RS',
      name: 'global-serbia',
      currency: 'RSD'
    },
    '7500': {
      code: 'SL',
      name: 'global-slovenia',
      currency: 'EUR'
    },
    '7600': {
      code: 'HR',
      name: 'global-croatia',
      currency: 'HRK'
    },
    '7700': {
      code: 'RO',
      name: 'global-romania',
      currency: 'EUR'
    },
    '7800': {
      code: 'BG',
      name: 'global-bulgaria',
      currency: 'BGN'
    },
    '7900': {
      code: 'SK',
      name: 'global-slovakia',
      currency: 'EUR'
    },
    '8000': {
      code: 'TR',
      name: 'global-turkey',
      currency: 'TRY'
    },
    '8100': {
      code: 'US',
      name: 'global-usa',
      currency: 'USD'
    },
    '8200': {
      code: 'UAE',
      name: 'global-uae',
      currency: 'AED'
    },
    '8700': {
      code: 'LT',
      name: 'global-luthuania',
      currency: 'EUR'
    },
    '8900': {
      code: 'LV',
      name: 'global-latvia',
      currency: 'EUR'
    },
    '9000': {
      code: 'RU',
      name: 'global-russia',
      currency: 'RUB'
    },
    '9200': {
      code: 'UA',
      name: 'global-ukraine',
      currency: 'UAH'
    },
    '9500': {
      code: 'PL',
      name: 'global-poland',
      currency: 'PLN'
    },
    '9600': {
      code: 'SE',
      name: 'global-sweden',
      currency: 'SEK'
    },
    '9700': {
      code: 'CZ',
      name: 'global-czech',
      currency: 'CZK'
    },
    '9800': {
      code: 'HU',
      name: 'global-hungary',
      currency: 'HUF'
    },
    '9900': {
      code: 'CA',
      name: 'global-canada',
      currency: 'CAD'
    }
  }
}
