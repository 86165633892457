import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LicenseDialogComponent } from '../license-dialog/license-dialog.component';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version: string = environment.version;

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public openViessmannWebsite(): void {
    window.open('https://www.viessmann.de/');
  }

  public showLicences() : void {
    this.dialog.open(LicenseDialogComponent);
  }

}
