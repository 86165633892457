<ng-container>
  <mat-card class="background">
    <h2 class="background-header">
      <!--Edit the configuration of trial versions from Vitoguide-->
      {{'home-home.component.html-heading' | translate}}
    </h2>
    <mat-card-content>
      <mat-tab-group mat-stretch-tabs class="tab-container " (selectedIndexChange)="onTabChanged($event)">
        <!--Countries-->
        <mat-tab matSort (matSortChange)="onSortChanged($event)"
                 label="{{'home-home.component.html-tab-countries' | translate}}">
          <div class="table-headers">
            <!--CountryModel name-->
            <div mat-sort-header="name" class="spread-equally">
              <b>{{'home-home.component.html-sort-country' |translate}}</b></div>
            <!--Number of devices-->
            <div mat-sort-header="amount"
                 class="spread-equally">{{'home-home.component.html-sort-amount' | translate}}</div>
            <!--Contract duration-->
            <div mat-sort-header="duration"
                 class="spread-equally">{{'home-home.component.html-sort-duration' | translate}}</div>
            <!--Trials only-->
            <div mat-sort-header="trialOnly"
                 class="spread-equally">{{'home-home.component.html-sort-trialsonly' | translate}}</div>
            <!--Price-->
            <div mat-header="price"
                 class="spread-equally">{{'home-home.component.html-sort-prices' | translate}}</div>
          </div>
          <mat-progress-bar class="progress-bar" *ngIf="!countryConfigs" mode="buffer"></mat-progress-bar>
          <mat-accordion *ngIf="countryConfigs" class="country-container" multi>
            <app-country-list-item class="country-list-item" [country]="country"
                                   *ngFor="let country of countryConfigsSorted"
                                   (onSaved)="changedCountry($event)" ></app-country-list-item>
          </mat-accordion>

          <div class="add-country-container">
            <button mat-raised-button color="primary" (click)="addCountry()" class="add-country-button">{{'home-home.component.html-addcountry' | translate}}</button>
          </div>
        </mat-tab>
        <!--Customers-->
        <mat-tab label="{{'home-home.component.html-tab-customers' | translate}}">
          <mat-form-field class="search-bar" appearance="standard">
            <!--Search customer number-->
            <mat-label>{{'home-home.component.html-inputlabel-customernumber' | translate}}</mat-label>
            <!--e.g.-->
            <input matInput placeholder="{{'global-forexample' | translate}} 0090075423" #input
                   (keyup)="paginator.pageIndex = 0; paginator.pageSize = 5; reloadCustomerData(input.value);">
          </mat-form-field>
          <mat-progress-bar class="progress-bar" *ngIf="!customerConfigs" mode="buffer"></mat-progress-bar>
          <table mat-table class="customer-table" [dataSource]="customerConfigsTableSource" *ngIf="customerConfigs"
                 multiTemplateDataRows>

            <ng-container matColumnDef="id">
              <!--CustomerModel number-->
              <th mat-header-cell class="center-text"
                  *matHeaderCellDef> {{'home-home.component.html-tableheader-customernumber' | translate}} </th>
              <td mat-cell class="center-text" *matCellDef="let element"> {{element.companyNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <!--Number of devices-->
              <th mat-header-cell class="center-text"
                  *matHeaderCellDef> {{'home-home.component.html-sort-amount' | translate}} </th>
              <td mat-cell class="center-text"
                  [class.text-yellow]="editedCustomer && element === editedCustomerOriginal && editedCustomer.totalAvailableTrials != element.totalAvailableTrials"
                  *matCellDef="let element"> {{element.totalAvailableTrials}} </td>
            </ng-container>

            <ng-container matColumnDef="duration">
              <!--Contract duration-->
              <th mat-header-cell class="center-text"
                  *matHeaderCellDef> {{'home-home.component.html-sort-duration' | translate}} </th>
              <td mat-cell class="center-text" *matCellDef="let element">
                <div *ngIf="element.duration && element.duration.years"
                     [class.text-yellow]="editedCustomer && element === editedCustomerOriginal && editedCustomer.duration.years != element.duration.years"
                     [ngPlural]="element.duration.years">
                  {{element.duration.years}}
                  <!--Year | Years-->
                  <ng-template ngPluralCase="=1"> {{'home-year' | translate}}</ng-template>
                  <ng-template ngPluralCase="other"> {{'home-years' | translate}}</ng-template>
                </div>
                <div *ngIf="element.duration && element.duration.months"
                     [class.text-yellow]="editedCustomer && element === editedCustomerOriginal && editedCustomer.duration.months != element.duration.months"
                     [ngPlural]="element.duration.months">
                  {{element.duration.months}}
                  <!--Month | Months-->
                  <ng-template ngPluralCase="=1"> {{'home-month' | translate}}</ng-template>
                  <ng-template ngPluralCase="other"> {{'home-months' | translate}}</ng-template>
                </div>
                <div *ngIf="element.duration && element.duration.weeks"
                     [class.text-yellow]="editedCustomer && element === editedCustomerOriginal && editedCustomer.duration.weeks != element.duration.weeks"
                     [ngPlural]="element.duration.weeks">
                  {{element.duration.weeks}}
                  <!--Week | Weeks-->
                  <ng-template ngPluralCase="=1"> {{'home-week' | translate}}</ng-template>
                  <ng-template ngPluralCase="other"> {{'home-weeks' | translate}}</ng-template>
                </div>
                <div *ngIf="element.duration && element.duration.days"
                     [class.text-yellow]="editedCustomer && element === editedCustomerOriginal && editedCustomer.duration.days != element.duration.days"
                     [ngPlural]="element.duration.days">
                  {{element.duration.days}}
                  <!--Day | Days-->
                  <ng-template ngPluralCase="=1"> {{'home-day' | translate}}</ng-template>
                  <ng-template ngPluralCase="other"> {{'home-days' | translate}}</ng-template>
                </div>
                <div *ngIf="!element.duration"
                     [class.text-yellow]="editedCustomer && element === editedCustomerOriginal">
                  {{'global-undefined' | translate}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="edit">
              <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsCustomer.length">
                <div class="expanded-section"
                     [@detailExpand]="element === editedCustomerOriginal ? 'expanded' : 'collapsed'">
                  <div *ngIf="editedCustomer" class="customer-table" >
                    <fieldset class="fieldset" [disabled]="saving">
                      <div [ngClass]="{'flex-change-axis': screenBreakpointReached}" class="edit-section">
                        <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="spread-equally" style="display: block">
                          <p>
                            <img *ngIf="editedCustomer.countryCode"
                                 src="https://www.countryflags.io/{{editedCustomer.countryCode}}/shiny/16.png"
                                 class="country-icon" alt="">
                            {{editedCustomer.name}}
                          </p>
                          <p>
                            {{editedCustomer.email}}
                          </p>
                        </div>
                        <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="spread-equally">
                          <mat-form-field class="spread-equally deep-scale" appearance="fill">
                            <!--New number of devices-->
                            <mat-label>{{'home-country-list-item.component.html-input-newamount' | translate}}</mat-label>
                            <input matInput class="number-field" type="number" min="0"
                                   [(ngModel)]="editedCustomer.totalAvailableTrials">
                          </mat-form-field>
                        </div>
                        <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="spread-equally">
                          <mat-form-field class="spread-equally deep-scale" appearance="fill"
                                          *ngIf="editedDurationUnit && editedCustomer.duration">
                            <!--New duration-->
                            <mat-label>{{'home-country-list-item.component.html-input-newduration' | translate}}</mat-label>
                            <input matInput class="number-field" type="number" min="0" [ngModel]="editedDurationLength"
                                   (ngModelChange)="setDurationLength($event)">
                          </mat-form-field>
                          <mat-form-field class="spread-equally deep-scale" appearance="fill"
                                          *ngIf="editedCustomer.duration && editedDurationUnit">
                            <!--Unit-->
                            <mat-label>{{'home-country-list-item.component.html-input-unit' | translate}}</mat-label>
                            <mat-select [ngModel]="editedDurationUnit" (ngModelChange)="setDurationUnit($event)">
                              <mat-option value="years" [ngPlural]="editedCustomer.duration[editedDurationUnit]">
                                <ng-template ngPluralCase="=1">{{'home-year' | translate}}</ng-template>
                                <ng-template ngPluralCase="other">{{'home-years' | translate}}</ng-template>
                              </mat-option>
                              <mat-option value="months" [ngPlural]="editedCustomer.duration[editedDurationUnit]">
                                <ng-template ngPluralCase="=1">{{'home-month' | translate}}</ng-template>
                                <ng-template ngPluralCase="other">{{'home-months' | translate}}</ng-template>
                              </mat-option>
                              <mat-option value="weeks" [ngPlural]="editedCustomer.duration[editedDurationUnit]">
                                <ng-template ngPluralCase="=1">{{'home-week' | translate}}</ng-template>
                                <ng-template ngPluralCase="other">{{'home-weeks' | translate}}</ng-template>
                              </mat-option>
                              <mat-option value="days" [ngPlural]="editedCustomer.duration[editedDurationUnit]">
                                <ng-template ngPluralCase="=1">{{'home-day' | translate}}</ng-template>
                                <ng-template ngPluralCase="other">{{'home-days' | translate}}</ng-template>
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="comment-container">
                          <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="comment-input">
                            <mat-form-field class="spread-equally deep-scale" appearance="fill">
                              <!--Comment field for configuration-->
                              <mat-label>{{'home-country-list-item.component.html-input-comment' | translate}}</mat-label>
                              <textarea rows="10" matInput type="text" [(ngModel)]="editedCustomer.comments"></textarea>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="editedCustomer.subscriptions && editedCustomer.subscriptions.length > 0">
                        <div class="buttons-container">
                          <div class="spread-equally center-text bold-text">{{'home-home.component.html-devicenumber' | translate}}</div>
                          <div class="spread-equally center-text bold-text">{{'home-home.component.html-period' | translate}}</div>
                          <div class="spread-equally center-text bold-text">{{'home-home.component.html-status' | translate}}</div>
                        </div>
                        <app-subscription-item *ngFor="let subscription of editedCustomer.subscriptions" [subscription]="subscription"></app-subscription-item>
                      </div>
                      <div class="center-text bold-text">
                        {{editedCustomer.remainingTrials}} {{'home-home.component.html-remaining' | translate}}
                      </div>
                      <div class="buttons-container">
                        <button mat-raised-button class="delete-customer-button"
                                (click)="deleteCustomer()">{{'home-home.component.html-deleteconfig' | translate}}</button>
                        <div class="cancel-save-button-container">
                          <!--Cancel-->
                          <button mat-raised-button class="cancel-edit-customer-button"
                                  (click)="cancelEditCustomer()">{{'global-cancel' | translate}}</button>
                          <!--Save-->
                          <button mat-raised-button color="primary"
                                  (click)="saveCustomer()">{{'global-save' | translate}}</button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <mat-progress-bar mode="indeterminate" *ngIf="!editedCustomer || saving"></mat-progress-bar>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsCustomer"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumnsCustomer;" class="element-row"
                [class.expanded-row]="editedCustomerOriginal" (click)="editCustomer(element)"></tr>
            <tr mat-row *matRowDef="let element; columns: ['edit']" class="edit-row"></tr>

            <tr class="mat-row" *matNoDataRow>
              <!--No entries found for "{{}}"-->
              <td class="mat-cell" [colSpan]="displayedColumnsCustomer.length">
                {{'home-home.component.html-nodatarow' | translate:{value: input.value} }}
              </td>
            </tr>
          </table>

          <div class="add-customer-button-container">
            <button mat-raised-button color="primary" (click)="addCustomer()" class="add-customer-button">{{'home-home.component.html-addcustomer' | translate}}</button>
          </div>

          <mat-paginator #paginator
                         class="pagination-custom-size"
                         [disabled]="editedCustomer != undefined"
                         [pageSizeOptions]="[5, 10, 25, 100]"
                         [length]="trialConfigurationService.totalCustomers"
                         (page)="pageChanged(input.value, $event)"></mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</ng-container>
