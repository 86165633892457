import { Injectable } from '@angular/core';
import { UnsavedChangesGuardService } from '../services/unsaved-changes.guard.service';

@Injectable()
export abstract class UserFormComponent {

  abstract hasUnsavedData(): boolean;

  onBeforeUnload($event: BeforeUnloadEvent) {
    if(this.hasUnsavedData()) {
      $event.preventDefault();
      $event.returnValue = UnsavedChangesGuardService.translatedWarning;
      return false;
    }
    return true;
  }
}
