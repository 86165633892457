<div class="subscription-container">
  <div class="spread-equally center">
    {{subscription.deviceId}}
  </div>
  <div class="spread-equally center">
    {{creationDate}} - {{expirationDate}}
  </div>
  <div class="spread-equally center">
    {{'global-' + subscription.status.toLowerCase() | translate}}
  </div>
</div>
