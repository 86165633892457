import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import { CountryModel, Duration } from '../models/country.model';
import { environment } from '../../../../environments/environment';
import {catchError, map, switchMap} from 'rxjs/operators';
import { CountryConfiguration } from '../../../../configurations/country.configuration';
import { CustomerModel } from '../models/customer.model';
import { AddDialogComponent } from '../components/add-dialog/add-dialog.component';
import { CustomerHttpResponseModel } from '../models/customer-http-response.model';
import { CustomerEmailHttpResponseModel } from '../models/customer-email-http-reponse.model';
import { CustomerSubscriptionResponseModel } from '../models/customer-subscription-response.model';
import {SubscriptionPlans} from "../models/subscription-plans.model";


@Injectable({
  providedIn: 'root'
})
export class TrialConfigurationService {

  public countryConfig = CountryConfiguration.config;
  public totalCustomers: number = 0;

  constructor(private httpClient: HttpClient) {
  }

  public getCountryData(): Observable<CountryModel[]> {
    return this.httpClient.get<CountryModel[]>(`${environment.http.monetizationBaseUrl}/vitoguide/subscriptions/trial/configuration`).pipe(map(countries => {
      return countries.map(country => this.getAdditionellCountryData(country));
    }));
  }

  public getCustomerData(input: string | undefined, page: number, pageSize: number): Observable<CustomerModel[]> {
    let params = new HttpParams().set('page', page).set('limit', pageSize);
    if (input) {
      params = params.set('companyId', input);
    }
    return this.httpClient.get<CustomerHttpResponseModel>(`${environment.http.monetizationBaseUrl}/vitoguide/subscriptions/trial/budget`, {params}).pipe(map(response => {
      this.totalCustomers = response.pagination.totalCount;
      return response.data as CustomerModel[];
    }));
  }

  public getAdditionellCountryData(country: CountryModel): CountryModel {
    const additionalData: CountryModel = this.countryConfig[country.vkorg || ''];
    if (!additionalData) return country;
    return {
      name: additionalData.name,
      code: additionalData.code,
      currency: additionalData.currency,
      ...country
    }
  }

  public getAdditionellCustomerData(customer: CustomerModel): Observable<CustomerModel> {
    return this.checkIfCustomerExists(customer.companyNumber).pipe(
      switchMap(() => forkJoin([this.getCustomerEmail(customer.companyNumber), this.getCustomerSubscritions(customer.companyNumber)]))
    ).pipe(map(([email, subscriptions]) => {
        return {
          name: email?.name,
          countryCode: email?.name,
          email: email?.email,
          ...customer,
          duration: customer.duration ? {...customer.duration} as Duration : AddDialogComponent.getDefaultDuration(),
          subscriptions: subscriptions.createdTrialVersions,
          remainingTrials: subscriptions.remaining
        };
      }
    ));
  }

  public checkIfCustomerExists(companyNumber: string): Observable<CustomerEmailHttpResponseModel> {
    return this.httpClient.get<CustomerEmailHttpResponseModel>(`${environment.http.companyBaseUrl}/companies/${companyNumber}/exist`);
  }

  private getCustomerEmail(companyNumber: string): Observable<CustomerEmailHttpResponseModel> {
    return this.httpClient.get<CustomerEmailHttpResponseModel>(`${environment.http.companyBaseUrl}/companies/${companyNumber}/email`).pipe(
      catchError(() => {
        return of({
          countryCode: '',
          communicationLanguage: '',
          name: '',
          email: ''
        })
      })
    );
  }

  private getCustomerSubscritions(companyNumber: string): Observable<CustomerSubscriptionResponseModel> {
    const params = new HttpParams().set('companyId', companyNumber);
    return this.httpClient.get<CustomerSubscriptionResponseModel>(`${environment.http.monetizationBaseUrl}/vitoguide/subscriptions/trial`, {params});
  }

  public getBasePrices(): Observable<SubscriptionPlans[]> {
    return this.httpClient.get<SubscriptionPlans[]>(`${environment.http.monetizationBaseUrl}/vitoguide/subscriptions/plans-base-prices/`);
  }

  public saveCountryConfiguration(changedConfig: CountryModel): Observable<void> {
    return this.httpClient.post<void>(`${environment.http.monetizationBaseUrl}/vitoguide/subscriptions/trial/configuration`, {
      vkorg: changedConfig.vkorg,
      amount: changedConfig.amount,
      trialOnly: changedConfig.trialOnly,
      duration: changedConfig.duration,
      comments: changedConfig.comments?.trim()
    } as CountryModel);
  }

  public updateBasePrices(subscriptionPlans : SubscriptionPlans){
    return this.httpClient.patch(`${environment.http.monetizationBaseUrl}/vitoguide/subscriptions/plans-base-prices/${subscriptionPlans.vkorg}`, {
      vkorg: subscriptionPlans.vkorg,
      plans: subscriptionPlans.plans
    });
  }

  public saveCustomerConfiguration(changedConfig: CustomerModel): Observable<void> {
    return this.httpClient.patch<void>(
      `${environment.http.monetizationBaseUrl}/vitoguide/subscriptions/trial/${changedConfig.companyNumber}/budget`,
      {
        budget: changedConfig.totalAvailableTrials,
        duration: changedConfig.duration,
        comments: changedConfig.comments?.trim(),
      }
    );
  }

  public deleteCustomerConfiguration(customerNumber: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.http.monetizationBaseUrl}/vitoguide/subscriptions/trial/${customerNumber}/budget`);
  }
}
