import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { UserFormComponent } from '../components/user-form.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable()
export class UnsavedChangesGuardService implements CanDeactivate<UserFormComponent> {

  public static translatedWarning = 'You have unsaved changes! If you leave, your changes will be lost.';

  constructor(private translate: TranslateService) {
    translate.get('global-warning-unsavedchanges').subscribe((result: string) => UnsavedChangesGuardService.translatedWarning = result);
  }

  canDeactivate(component: UserFormComponent): boolean | Observable<boolean> {
    if(component.hasUnsavedData()){
      return confirm(UnsavedChangesGuardService.translatedWarning);
    }
    return true;
  }
}
