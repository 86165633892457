import { Component, Input, OnInit } from '@angular/core';
import { CustomerSubscriptionModel } from '../../models/customer-subscription.model';

@Component({
  selector: 'app-subscription-item',
  templateUrl: './subscription-item.component.html',
  styleUrls: ['./subscription-item.component.scss']
})
export class SubscriptionItemComponent implements OnInit {

  @Input() public subscription!: CustomerSubscriptionModel;

  public creationDate!: string;
  public expirationDate!: string;

  constructor() {

  }

  ngOnInit(): void {
    this.creationDate = new Date(this.subscription.createdAt).toLocaleDateString();
    this.expirationDate = new Date(this.subscription.expirationDate).toLocaleDateString();
  }

}
