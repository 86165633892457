import { Environment } from './environment.model';
import { appVersion } from './app-version';

export const environment: Environment = {
  production: false,
  env: 'staging',
  version: appVersion,
  auth: {
    appId: 'vitoguide-trial',
    baseUrl: 'https://api-staging.viessmann.com',
    registerBaseUrl: 'https://account-staging.viessmann.com',
  },
  usersUrl: 'https://api-staging.viessmann.com/users/v1/users/me',
  apiUrl: 'https://api-staging.viessmann.com/',
  http: {
    monetizationBaseUrl: 'https://api-staging.viessmann.com/monetization/v1',
    companyBaseUrl: 'https://api-staging.viessmann.com/company/v3'
  }
};
