import { Component, OnInit, Input } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {timeout} from "rxjs/operators";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  public $errData = {
    status: -1,
    message: ""
  }

  private redirectLink: string = "";

  constructor() { }

  public setData(status: number, message: string, redirectLink?: string): void {
    this.$errData.status = status;
    this.$errData.message = message;
    if(redirectLink == undefined) return;
    this.redirectLink = redirectLink;
    ((async () => {
      await new Promise(f => {
        setTimeout(f, 10000);
      })
      this.redirect();
    }))();
  }

  public redirect(): void {
    if(this.redirectLink != "") {
      window.location.href = this.redirectLink;
      this.redirectLink = "";
    }
  }

}
