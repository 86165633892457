import { Component, OnInit } from '@angular/core';
import { CountryModel } from '../../models/country.model';
import { CustomerModel } from '../../models/customer.model';
import { TrialConfigurationService } from '../../services/trial.configuration.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Duration } from '../../models/duration.model';

@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss']
})
export class AddDialogComponent implements OnInit {

  public country: CountryModel | undefined;
  public customer: CustomerModel | undefined;

  public saving: boolean = false;

  public customerFormControl: FormControl = new FormControl();

  constructor(private dialogRef: MatDialogRef<AddDialogComponent>, private trialConfigurationService: TrialConfigurationService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  public createCountry(): MatDialogRef<AddDialogComponent> {
    this.country = {
      vkorg: '',
      amount: 1,
      duration: AddDialogComponent.getDefaultDuration(),
      trialOnly: true
    }
    return this.dialogRef;
  }

  public createCustomer(): MatDialogRef<AddDialogComponent> {
    this.customer = {
      companyNumber: '',
      totalAvailableTrials: 1,
      duration: AddDialogComponent.getDefaultDuration()
    }
    return this.dialogRef;
  }

  public saveConfiguration(): void {
    if (this.country) {
      this.saving = true;
      this.catchSavingResult(this.trialConfigurationService.saveCountryConfiguration(this.country));
    }
    if (this.customer) {
      this.saving = true;
      this.validateCustomerNumber()?.subscribe(() => {
        if (this.customer && this.customer?.companyNumber) {
          this.catchSavingResult(this.trialConfigurationService.saveCustomerConfiguration(this.customer))
        }
      });
    }
  }

  public cancel(): void {
    this.country = undefined;
    this.customer = undefined;
    this.close();
  }

  public updateCountryInfo(input: string) {
    const additionellInfo: CountryModel = this.trialConfigurationService.getAdditionellCountryData(this.country!);
    if (additionellInfo === this.country) {
      this.createCountry();
      this.country.vkorg = input;
    } else {
      this.country = additionellInfo;
    }
  }

  public validateCustomerNumber(): Observable<CustomerModel> | undefined {
    if(!this.customer) return undefined;
    return this.trialConfigurationService.getAdditionellCustomerData(this.customer).pipe(
      catchError(error => {
        this.customerFormControl.setErrors({invalidCustomerNumber: this.customer!.companyNumber});
        this.customerFormControl.markAsTouched();
        this.createCustomer();
        this.customer!.companyNumber = this.customerFormControl.value;
        this.saving = false;
        throw error;
      }),
      map(customer => {
        if (customer?.companyNumber) {
          this.customer = customer;
          this.customerFormControl.setErrors(null);
          this.customerFormControl.markAsTouched();
        }
        return customer;
      }));
  }

  private close(): void {
    this.dialogRef.close({country: this.country, customer: this.customer});
  }

  private catchSavingResult(result: Observable<any>) {
    result.pipe(
      catchError(err => {
        this.dialog.open(ErrorDialogComponent).componentInstance.$errData = {
          status: err.status,
          message: err.message
        }
        this.saving = false;
        throw(err);
      })).subscribe(() => {
      this.close();
      this.country = undefined;
      this.customer = undefined;
      this.saving = false;
    });
  }

  public static getDefaultDuration(): Duration {
    return {months: 6} as Duration;
  }
}
