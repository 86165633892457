<mat-expansion-panel class="country-list-item-panel" [expanded]="tempChanges!=undefined" (opened)="setSelected()">
  <mat-expansion-panel-header>
    <mat-panel-title class="country-list-item-title">
      <img *ngIf="country.code" src="https://www.countryflags.io/{{country.code}}/shiny/16.png"
           class="country-icon" alt=""/>
      <div *ngIf="country.name">
        {{country.name | translate}}
      </div>
      <div *ngIf="!country.name" [ngClass]="{'text-yellow': otherCountries.length > 0}">
        {{'home-country-list-item.component.html-countrynotconfigured' | translate:{value: country.vkorg} }}
      </div>
    </mat-panel-title>
    <mat-panel-description>
      <div class="spread-equally centered"
           [ngClass]="{'text-yellow': tempChanges && country.amount != tempChanges.amount}" *ngIf="country.amount">
        {{country.amount}}
      </div>
      <div *ngIf="country.duration" class="duration spread-equally centered">
        <div class="centered"
             [ngClass]="{'text-yellow': tempChanges && tempChanges.duration && country.duration.years != tempChanges.duration.years}"
             [ngPlural]="country.duration.years" *ngIf="country.duration.years">
          {{country.duration.years}}
          <!--Year | Years-->
          <ng-template ngPluralCase="=1"> {{'home-year' | translate}}</ng-template>
          <ng-template ngPluralCase="other"> {{'home-years' | translate}}</ng-template>
        </div>
        <div class="spread-equally centered"
             [ngClass]="{'text-yellow': tempChanges && tempChanges.duration && country.duration.months != tempChanges.duration.months}"
             [ngPlural]="country.duration.months" *ngIf="country.duration.months">
          {{country.duration.months}}
          <!--Month | Months-->
          <ng-template ngPluralCase="=1"> {{'home-month' | translate}}</ng-template>
          <ng-template ngPluralCase="other"> {{'home-months' | translate}}</ng-template>
        </div>
        <div class="spread-equally centered"
             [ngClass]="{'text-yellow': tempChanges && tempChanges.duration && country.duration.weeks != tempChanges.duration.weeks}"
             [ngPlural]="country.duration.weeks" *ngIf="country.duration.weeks">
          {{country.duration.weeks}}
          <!--Week | Weeks-->
          <ng-template ngPluralCase="=1"> {{'home-week' | translate}}</ng-template>
          <ng-template ngPluralCase="other"> {{'home-weeks' | translate}}</ng-template>
        </div>
        <div class="spread-equally centered"
             [ngClass]="{'text-yellow': tempChanges && tempChanges.duration && country.duration.days != tempChanges.duration.days}"
             [ngPlural]="country.duration.days" *ngIf="country.duration.days">
          {{country.duration.days}}
          <!--Tag | Tage-->
          <ng-template ngPluralCase="=1"> {{'home-day' | translate}}</ng-template>
          <ng-template ngPluralCase="other"> {{'home-days' | translate}}</ng-template>
        </div>
      </div>
      <div class="spread-equally centered">
        <mat-icon *ngIf="country.trialOnly"
                  [ngClass]="{'green': !tempChanges || country.trialOnly === tempChanges.trialOnly, 'text-yellow': tempChanges && country.trialOnly != tempChanges.trialOnly}"
                  class="green">
          check mark
        </mat-icon>
        <mat-icon *ngIf="!country.trialOnly"
                  [ngClass]="{'text-yellow': tempChanges && country.trialOnly != tempChanges.trialOnly}" color="warn">
          close
        </mat-icon>
      </div>

      <div class="spread-equally centered" *ngIf="country.subscriptionPlans">
        <table>
          <tbody>
          <tr *ngIf="country.subscriptionPlans.plans.Pro"
              [ngClass]="{'text-yellow': tempChanges && tempChanges.subscriptionPlans && country.subscriptionPlans.plans.Pro != tempChanges.subscriptionPlans.plans.Pro}">
            <td>{{'home-country-list-item.component.html-subscriptionplan-pro' | translate}}:</td>
            <td>{{country.subscriptionPlans.plans.Pro | currency: country.currency}}</td>
          </tr>
          <tr *ngIf="country.subscriptionPlans.plans.Plus"
              [ngClass]="{'text-yellow': tempChanges && tempChanges.subscriptionPlans && country.subscriptionPlans.plans.Plus != tempChanges.subscriptionPlans.plans.Plus}">
            <td>{{'home-country-list-item.component.html-subscriptionplan-plus' | translate}}:</td>
            <td>{{country.subscriptionPlans.plans.Plus | currency: country.currency}}</td>
          </tr>
          <tr *ngIf="country.subscriptionPlans.plans.ProLegacy"
              [ngClass]="{'text-yellow': tempChanges && tempChanges.subscriptionPlans && country.subscriptionPlans.plans.ProLegacy != tempChanges.subscriptionPlans.plans.ProLegacy}">
            <td>{{'home-country-list-item.component.html-subscriptionplan-prolegacy' | translate}}:</td>
            <td>{{country.subscriptionPlans.plans.ProLegacy | currency: country.currency}}</td>
          </tr>
          <tr *ngIf="country.subscriptionPlans.plans.PlusLegacy"
              [ngClass]="{'text-yellow': tempChanges && tempChanges.subscriptionPlans && country.subscriptionPlans.plans.PlusLegacy != tempChanges.subscriptionPlans.plans.PlusLegacy}">
            <td>{{'home-country-list-item.component.html-subscriptionplan-pluslegacy' | translate}}:</td>
            <td>{{country.subscriptionPlans.plans.PlusLegacy | currency: country.currency}}</td>
          </tr>
          <tr *ngIf="country.subscriptionPlans.plans.Basic"
              [ngClass]="{'text-yellow': tempChanges && tempChanges.subscriptionPlans && country.subscriptionPlans.plans.Basic != tempChanges.subscriptionPlans.plans.Basic}">
            <td>{{'home-country-list-item.component.html-subscriptionplan-basic' | translate}}:</td>
            <td>{{country.subscriptionPlans.plans.Basic | currency: country.currency}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="spread-equally centered" *ngIf="!country.subscriptionPlans">
        {{'home-country-list-item.component.html-noprices' | translate: {value: country.vkorg} }}
      </div>

    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <fieldset class="fieldset" [disabled]="saving">
      <div [ngClass]="{'flex-change-axis': screenBreakpointReached}" class="div-row" *ngIf="tempChanges">
        <mat-form-field [ngClass]="{'scale-change-full-width': screenBreakpointReached}"
                        class="spread-equally deep-scale" appearance="fill">
          <!--Apply to other countries as well-->
          <mat-label>{{'home-country-list-item.component.html-inputlabel-othercountries' | translate}}</mat-label>
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let otherCountry of otherCountries" [selectable]="false"
                      (removed)="removedOtherCountry(otherCountry.name)">
              <div *ngIf="otherCountry.name">
                {{otherCountry.name | translate}}
              </div>
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <!--Other country's name-->
            <input class="spread-equally"
                   placeholder="{{'home-country-list-item.component.html-inputlabel-othercountry' | translate}}"
                   #otherCountryInput
                   [formControl]="otherCountriesFormControl"
                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeyCodes">
            <!--No country with name "{{}}" found-->
            <mat-error
              *ngIf="otherCountriesFormControl.hasError('invalidCountry')">{{'home-country-list-item.component.html-error-othercountry' | translate:{value: otherCountryInput.value} }}
            </mat-error>
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedOtherCountry($event)">
            <mat-option *ngFor="let filteredCountry of filteredCountries | async" [value]="filteredCountry">
              {{filteredCountry}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="spread-equally centered">
          <mat-form-field class="spread-equally deep-scale" appearance="fill">
            <!--New number of devices-->
            <mat-label>{{'home-country-list-item.component.html-input-newamount' | translate}}</mat-label>
            <input class="number-field" matInput type="number" min="0"
                   [(ngModel)]="tempChanges.amount">
          </mat-form-field>
        </div>
        <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="spread-equally centered">
          <mat-form-field class="spread-equally deep-scale" appearance="fill"
                          *ngIf="durationUnit && tempChanges.duration">
            <!--New duration-->
            <mat-label>{{'home-country-list-item.component.html-input-newduration' | translate}}</mat-label>
            <input matInput class="number-field" type="number" min="0" [ngModel]="durationLength"
                   (ngModelChange)="setDurationLength($event)">
          </mat-form-field>
          <mat-form-field class="spread-equally deep-scale" appearance="fill"
                          *ngIf="tempChanges.duration && durationUnit">
            <!--Unit-->
            <mat-label>{{'home-country-list-item.component.html-input-unit' | translate}}</mat-label>
            <mat-select [ngModel]="durationUnit" (ngModelChange)="setDurationUnit($event)">
              <mat-option value="years" [ngPlural]="tempChanges.duration[durationUnit]">
                <ng-template ngPluralCase="=1">{{'home-year' | translate}}</ng-template>
                <ng-template ngPluralCase="other">{{'home-years' | translate}}</ng-template>
              </mat-option>
              <mat-option value="months" [ngPlural]="tempChanges.duration[durationUnit]">
                <ng-template ngPluralCase="=1">{{'home-month' | translate}}</ng-template>
                <ng-template ngPluralCase="other">{{'home-months' | translate}}</ng-template>
              </mat-option>
              <mat-option value="weeks" [ngPlural]="tempChanges.duration[durationUnit]">
                <ng-template ngPluralCase="=1">{{'home-week' | translate}}</ng-template>
                <ng-template ngPluralCase="other">{{'home-weeks' | translate}}</ng-template>
              </mat-option>
              <mat-option value="days" [ngPlural]="tempChanges.duration[durationUnit]">
                <ng-template ngPluralCase="=1">{{'home-day' | translate}}</ng-template>
                <ng-template ngPluralCase="other">{{'home-days' | translate}}</ng-template>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="spread-equally centered">
          <mat-form-field *ngIf="tempChanges.subscriptionPlans" class="spread-equally deep-scale" appearance="fill">
            <!--Choose Subscription Plan-->
            <mat-label>{{'home-country-list-item.component.html-input-subscription-plan' | translate}}</mat-label>
            <mat-select *ngIf="tempChanges.subscriptionPlans" [ngModel]="subscriptionPlanName"
                        (ngModelChange)="setSubscriptionPlanName($event)">
              <mat-option value="Pro" *ngIf="tempChanges.subscriptionPlans.plans.Pro">
                {{'home-country-list-item.component.html-subscriptionplan-pro' | translate}}
              </mat-option>
              <mat-option value="Plus" *ngIf="tempChanges.subscriptionPlans.plans.Plus">
                {{'home-country-list-item.component.html-subscriptionplan-plus' | translate}}
              </mat-option>
              <mat-option value="ProLegacy" *ngIf="tempChanges.subscriptionPlans.plans.ProLegacy">
                {{'home-country-list-item.component.html-subscriptionplan-prolegacy' | translate}}
              </mat-option>
              <mat-option value="PlusLegacy" *ngIf="tempChanges.subscriptionPlans.plans.PlusLegacy">
                {{'home-country-list-item.component.html-subscriptionplan-pluslegacy' | translate}}
              </mat-option>
              <mat-option value="Basic" *ngIf="tempChanges.subscriptionPlans.plans.Basic">
                {{'home-country-list-item.component.html-subscriptionplan-basic' | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="tempChanges.subscriptionPlans" class="spread-equally deep-scale" appearance="fill">
            <!--New Price-->
            <mat-label>{{'home-country-list-item.component.html-input-newprice' | translate}}</mat-label>
            <input [ngModel]="subscriptionPlanPrice" (ngModelChange)="setSubscriptionPlanPrice($event)"
                   class="number-field" matInput type="number" min="0">
          </mat-form-field>
        </div>

        <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="spread-equally centered">
          <mat-checkbox
            class="example-margin"
            [(ngModel)]="tempChanges.trialOnly"
            labelPosition="before"
            color="primary">
            <!--Trials only-->
            {{'home-home.component.html-sort-trialsonly' | translate}}
          </mat-checkbox>
        </div>

        <div class="comment-container">
          <div [ngClass]="{'scale-change-full-width': screenBreakpointReached}" class="comment-input">
            <mat-form-field class="spread-equally deep-scale" appearance="fill">
              <!--Comment field for configuration-->
              <mat-label>{{'home-country-list-item.component.html-input-comment' | translate}}</mat-label>
              <textarea rows="10" matInput type="text" [(ngModel)]="tempChanges.comments"></textarea>
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="buttons-container">
        <!--Cancel-->
        <button mat-raised-button class="cancel-button" (click)="cancel()">{{'global-cancel' | translate}}</button>
        <!--Save-->
        <button mat-raised-button color="primary" (click)="save()">{{'global-save' | translate}}</button>
      </div>
      <mat-progress-bar class="progress-bar" mode="indeterminate" *ngIf="saving"></mat-progress-bar>
    </fieldset>
  </ng-template>
</mat-expansion-panel>
