import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlobalHeaderModule } from '@vi/global-header';
import { environment } from '../environments/environment';
import { HttpInterceptorService } from './services/http.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationConfigService } from './services/translation-config.services';
import { HomeModule } from './modules/home/home.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http,
    `${TranslationConfigService.translationsBaseUrl}phraseapp-proxy/projects/${TranslationConfigService.phraseProjectId}/locales/`,
    '/download?file_format=i18next');
}

@NgModule({
  declarations: [
    AppComponent
  ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        GlobalHeaderModule.forRoot({
            baseUrl: environment.auth.baseUrl,
            partnerPortalBaseUrl: 'https://account-integration.viessmann.com',
            appId: environment.auth.appId
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            isolate: false
        }),
        HomeModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    TranslationConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
