import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private auth: AuthService, private userService: UserService, private router: Router) {}

  canActivate(): Observable<boolean> {
    // Looking for valid access token
    if (this.auth.hasValidCsrfToken()) {
      this.userService.hasViGuideTrialAccess$.subscribe((hasViGuideTrialAccess) => {
        if (!hasViGuideTrialAccess) {
          this.router.navigate(['forbidden']);
        }
      });

      // User is logged in
      return of(true);
    }
    // start csrf token authentication process
    return this.auth.fetchNewCsrfToken().pipe(map((res) => !!res.token));
  }
}
