import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, share, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public csrfToken$ = new BehaviorSubject<string | undefined>(undefined);
  private fetchNewCsrfToken$: Observable<{ token: string }> = new Observable(
    undefined
  );

  private httpClient: HttpClient;

  constructor(handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  public getCsrfToken(): string | undefined {
    return this.csrfToken$.value;
  }

  public getCsrfToken$() {
    return this.csrfToken$.pipe(
      filter((token) => token !== undefined && this.isCsrfTokenValid(token))
    );
  }

  init() {
    this.fetchNewCsrfToken$ = this.getCsrfTokenRequest().pipe(
      tap({
        next: ({ token }) => this.csrfToken$.next(token),
        error: () => this.redirectToLoginScreen(),
      }),
      share()
    );

    const token = this.getCsrfTokenFromUrl();

    if (token && this.isCsrfTokenValid(token)) {
      this.csrfToken$.next(token);
    } else {
      this.fetchNewCsrfToken$.subscribe();
    }
  }

  onLogout() {
    return;
  }

  fetchNewCsrfToken() {
    return this.fetchNewCsrfToken$;
  }

  public getCsrfTokenRequest() {
    return this.httpClient.post<{ token: string }>(
      `${environment.auth.baseUrl}/auth/v1/saml/csrf`,
      {
        appId: environment.auth.appId,
      },
      {
        withCredentials: true,
      }
    );
  }

  private getCsrfTokenFromUrl(): string | void {
    const hash = window.location.hash;
    if (hash?.startsWith('#token=')) {
      const value = new HttpParams({ fromString: hash }).get('#token');
      window.location.hash = ''; // remove token form url
      return value || undefined;
    }
  }

  private redirectToLoginScreen() {
    window.location.href = `${
      environment.auth.baseUrl
    }/saml/sso/request?appId=${
      environment.auth.appId
    }&redirectUrl=${encodeURIComponent(
      window.location.href
    )}&iambgimg=iam_lpi_000001.png`;
  }

  private isCsrfTokenValid(token: string) {
    try {
      return !this.isCSRFTokenExpired(token);
    } catch (_) {
      return false;
    }
  }

  isCSRFTokenExpired(token: string): boolean {
    const date = this.getTokenExpirationDate(token);
    if (!date) {
      return false;
    }
    return date.valueOf() <= new Date().valueOf();
  }

  getTokenExpirationDate(token: string): Date | null {
    const decoded = <{ exp?: number }>jwt_decode(token);

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  public hasValidCsrfToken(): boolean {
    const token = this.getCsrfToken();
    if (!token) {
      return false;
    }
    return this.isCsrfTokenValid(token);
  }

  public logout(redirectUrl: string): void {
    const form = window.document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute(
      'action',
      `${environment.auth.baseUrl}/auth/v1/saml/logout?redirectUrl=${redirectUrl}`
    );
    form.setAttribute('target', '_self');

    window.document.body.appendChild(form);
    form.submit();
  }
}
