import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CountryModel} from '../../models/country.model';
import {TrialConfigurationService} from '../../services/trial.configuration.service';
import {ErrorDialogComponent} from '../error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {catchError, map, startWith} from 'rxjs/operators';
import {COMMA, ENTER, SEMICOLON} from '@angular/cdk/keycodes';
import {forkJoin, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {TranslateService} from '@ngx-translate/core';
import {Duration} from '../../models/duration.model';
import {UserFormComponent} from '../../../../components/user-form.component';
import deepEqual from 'deep-equal';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'app-country-list-item',
  templateUrl: './country-list-item.component.html',
  styleUrls: ['./country-list-item.component.scss']
})
export class CountryListItemComponent extends UserFormComponent implements OnInit {
  public screenBreakpointReached: boolean = false;

  public separatorKeyCodes: number[] = [ENTER, COMMA, SEMICOLON];

  @Input() public country: CountryModel = {};
  public tempChanges: CountryModel | undefined;

  public saving: boolean = false;
  public durationLength: number | undefined;
  public durationUnit: string | undefined;
  public subscriptionPlanName: string | undefined;
  public subscriptionPlanPrice: number | undefined;

  public otherCountries: CountryModel[] = [];
  public otherCountriesFormControl: FormControl = new FormControl();
  public filteredCountries: Observable<(string | undefined)[]> = new Observable<(string | undefined)[]>();
  public allCountries: (string | undefined)[] = [];
  @ViewChild('otherCountryInput') otherCountryInput!: ElementRef<HTMLInputElement>;

  @Output() onSaved: EventEmitter<CountryModel> = new EventEmitter<CountryModel>();

  constructor(private trialConfigService: TrialConfigurationService, private dialog: MatDialog, private translate: TranslateService, private breakpointObserver: BreakpointObserver) {
    super();
  }

  ngOnInit() {
    this.breakpointObserver.observe('(max-width: 1000px)').subscribe((state: BreakpointState) => {
      this.screenBreakpointReached = state.matches;
    });
    Object.values(this.trialConfigService.countryConfig).map(x => {
      if (x.name && x.name != this.country.name) {
        this.translate.get(x.name).subscribe(y => {
          this.allCountries.push(y);
        })
      }
    });
    this.filteredCountries = this.otherCountriesFormControl.valueChanges.pipe(
      startWith(null),
      map((c: string | null) => c ? this.filter(c) : this.allCountries.slice())
    );
  }

  public setSelected(): void {
    if (!this.tempChanges) {
      this.tempChanges = deepCopyCountry(this.country);

      if (this.country.duration) {
        this.durationUnit = Object.keys(this.country.duration)[0];
        this.durationLength = this.country.duration[this.durationUnit];
      }
      if (this.country.subscriptionPlans) {
        this.subscriptionPlanName = Object.keys(this.country.subscriptionPlans.plans)[0];
        this.subscriptionPlanPrice = this.country.subscriptionPlans.plans[this.subscriptionPlanName];
      }
    }
  }

  public setSubscriptionPlanName(subscriptionPlanName: string) {
    if (this.tempChanges && this.tempChanges.subscriptionPlans) {
      this.subscriptionPlanName = subscriptionPlanName;
      this.subscriptionPlanPrice = this.tempChanges.subscriptionPlans.plans[subscriptionPlanName];
    }
  }

  public setSubscriptionPlanPrice(subscriptionPlanPrice: number) {
    if (this.tempChanges && this.subscriptionPlanName && this.tempChanges.subscriptionPlans) {
      this.subscriptionPlanPrice = subscriptionPlanPrice;
      this.tempChanges.subscriptionPlans.plans[this.subscriptionPlanName] = subscriptionPlanPrice;
    }
  }

  public setDurationLength(durationLength: number): void {
    if (this.tempChanges && this.durationUnit) {
      this.durationLength = durationLength;
      if (!this.tempChanges.duration) {
        this.tempChanges.duration = {} as Duration;
      }
      this.tempChanges.duration[this.durationUnit] = durationLength;
    }
  }

  public setDurationUnit(unit: string): void {
    if (this.tempChanges && this.durationLength) {
      this.durationUnit = unit;
      this.tempChanges.duration = {} as Duration;
      this.tempChanges.duration[unit] = this.durationLength;
    }
  }

  public save(): void {
    if (!this.tempChanges) {
      this.dialog.open(ErrorDialogComponent).componentInstance.$errData = {
        status: -1,
        message: 'global-error-occurred'
      }
      return;
    }
    this.saving = true;
    for (const other of this.otherCountries) {
      const editedOther = {...this.tempChanges, ...other};
      this.trialConfigService.saveCountryConfiguration(editedOther).subscribe(() => this.onSaved.emit(editedOther));
    }
    this.clearOtherCountries();

    let calls = [];

    if (this.tempChanges) {
      calls.push(this.trialConfigService.saveCountryConfiguration(this.tempChanges));
    }
    if (this.tempChanges.subscriptionPlans) {
      calls.push(this.trialConfigService.updateBasePrices(this.tempChanges.subscriptionPlans));
    }

    forkJoin(...calls).pipe(catchError(err => {
      this.dialog.open(ErrorDialogComponent).componentInstance.$errData = {
        status: err.status,
        message: err.message
      }
      this.saving = false;
      throw(err);
    })).subscribe(() => {
      if (this.tempChanges) {
        this.onSaved.emit(this.tempChanges);
        this.tempChanges = undefined;
      } else {
        this.country = {};
      }
      this.saving = false;
    });
  }

  public cancel(): void {
    this.clearOtherCountries();
    this.tempChanges = undefined;
  }

  public selectedOtherCountry(event: MatAutocompleteSelectedEvent): void {
    this.addOtherCountry(event.option.viewValue);
    this.otherCountryInput.nativeElement.value = '';
    this.otherCountriesFormControl.setValue(null);
  }

  private clearOtherCountries(): void {
    this.otherCountries = [];
    this.otherCountriesFormControl.setValue(null);
    this.otherCountryInput.nativeElement.value = '';
  }

  private addOtherCountry(countryName: string): void {
    Object.keys(this.trialConfigService.countryConfig).map(x => {
      let c: CountryModel = this.trialConfigService.countryConfig[x];
      if (c.name) {
        this.translate.get(c.name).subscribe(y => {
          if (y === countryName) {
            this.otherCountries.push({...c, vkorg: x});
            this.allCountries.splice(this.allCountries.indexOf(countryName), 1);
          }
        })
      }
    });
  }

  public removedOtherCountry(name: string | undefined): void {
    if (name) {
      this.translate.get(name).subscribe(x => this.allCountries.push(x));
      const index = this.otherCountries.findIndex(c => c.name === name);
      if (index >= 0) {
        this.otherCountries.splice(index, 1);
      }
    }
  }

  private filter(value: string): (string | undefined)[] {
    const filteredCountries: (string | undefined)[] = this.allCountries.filter(country => country ? country.toLowerCase().startsWith(value.toLowerCase()) : false);
    this.otherCountriesFormControl.setErrors(filteredCountries.length > 0 ? null : {invalidCountry: value});
    return filteredCountries;
  }

  hasUnsavedData(): boolean {
    return this.tempChanges && !deepEqual(this.tempChanges, this.country) || this.otherCountries.length > 0;
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event: BeforeUnloadEvent): boolean {
    return super.onBeforeUnload($event);
  }
}

function deepCopyCountry(country: CountryModel): CountryModel | undefined {
  if (!country) return undefined
  return JSON.parse(JSON.stringify(country))
}

